body {
  font-size: 1.1em;
  height: 100%;
  line-height: 1.8;
  color: white;
  font-family: 'Roboto Condensed', sans-serif;
}

footer {
  background-color: transparent;
  color: inherit;
  text-align: center;
  font-family: 'Bebas Neue', cursive;
  font-size: 12pt;
}

hr {
  border: solid yellow 0;
  border-width: 3px;
  max-width: 70px;
  margin: auto;
  margin-bottom: 20px;
  border-top: 1px;
}


h1 {
  font-family: 'Bebas Neue', cursive;
  color: white;
}

h2 {
  text-align: center;
  font-size: 2em;
  font-family: 'Bebas Neue', cursive;
}

h3 {
  color: white;
  font-family: 'Bebas Neue', cursive;
  font-size: larger;
}

a {
  text-decoration: none;
  color: inherit;
}

#wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
}

#github,
#email,
#linkedin {
  width: 400px;
  height: 400px;
  display: flex;
  margin: auto;
  background-image: url("./assets/wave.gif");
  border-radius: 50%;
}

#art {
  max-width: 100%;
  padding: 10pt;
  height: auto;
  width: 400px;
  border: yellow solid 2px;
  border-radius: 50pt;
  margin-top: auto;
  margin-left: 10%;
  margin-right: 5%;
  margin-bottom: 5%;
  float: right;
}

#usn {
  max-width: 100%;
  padding: 10pt;
  height: auto;
  width: 400px;
  border: yellow solid 2px;
  border-radius: 50pt;
  margin-top: 10%;
  margin-right: 10%;
  margin-left: 5%;
  margin-bottom: 5%;
  float: left;
}

#hike {
  max-width: 100%;
  padding: 10pt;
  height: 400px;
  width: 400px;
  border: yellow solid 2px;
  border-radius: 50pt;
  margin-top: 1%;
  margin-left: 10%;
  margin-right: 5%;
  margin-bottom: 5%;
  float: right;
}

#introimage {
  height: 450px;
  margin-left: 4%;
  margin-top: -8%;
  border-radius: 50%;
  float: right;
  display: flex;
  padding: 10px;
  border: yellow solid 3px;
}

#textalign {
  color: white;
  text-align: center;
  margin-top: 5%;
}

#ty {
  text-align: center;
  font-family: 'Bebas Neue', cursive;
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: 20px;
  }

  100% {
    margin-top: 0;
  }
}

.down-arrow {
  position: absolute;
  top: 85vh;
  left: 50%;
  width: 0;
  border: 2px solid;
  border-radius: 2px;
  animation: jumpInfinite 1.5s infinite;
  color: transparent;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -10px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
  color: white;
}

.finalpara {
  text-align: center;
}

.bannertext {
  text-align: center;
  position: relative;
  top: 15%;
  left: 50%;
  font-size: 13vh;
  transform: translate(-50%, -15%);
  font-family: 'Bebas Neue', cursive;
}

.bannertext p {
  color: white;
  font-size: 6vh;
  text-shadow: 2px 4px 4px grey;
}

.banner {
  height: 100vh;
  background-image: url("./assets/wave2.gif");
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  background-color: black;
}

.navContainer {
  display: flex;
  justify-content: center;
  font-size: 4vh;
  font-family: 'Bebas Neue', cursive;
}

.square {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  color: white;
}

.containerTextIntro {
  padding: 10% 20% 10% 20%;
  color: white;
  border: #838383 solid 2pt;
  border-left: none;
  border-right: none;
  background-image: url('./assets/sand.jpg');
  background-size: cover;
}

.containerTextIntro hr {
  margin: 0;
  margin-bottom: 15px;
}

.containerTextIntro h2 {
  color: white;
  text-align: start;
  text-shadow: none;
}

.containerText,
.containerTextAbout {
  margin: 0 20% 5% 20%;
}

.containerText2 {
  display: table;
  table-layout: fixed;
  border-spacing: 1.25vw;
  margin: 0 20% 0% 20%;
  text-align: center;
}

.carContainer {
  background-image: url("./assets/lines.jpg");
  background-size: cover;
  background-position: center;
  border-bottom: white solid 2pt;
}

.skillsContainer {
  background-image: url("./assets/darksand.jpg");
  background-size: cover;
  background-position: center;
}

.languagesTitle {
  padding-top: 1vh;
  text-align: center;
  color: white;
  text-shadow: none;
}

.carimg {
  display: block;
  border-radius: 50pt;
  cursor: grab;
  max-width: 100%;
  height: auto;
  width: 250px;
}

.skillsbox {
  display: table-cell;
  padding: 1vh;
  margin: auto;
  width: 30%;
  height: 50vh;
  color: white;
}

.skillsbox h3 {
  text-shadow: none;
}

.skillsbox li {
  font-size: 12pt;
}

.linkbox {
  display: flex;
}

.linkcolumn {
  flex: 30%;
  padding: 5px;
}

.footerMain {
  color: white;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.hover-underline-animation {
	display: inline-block;
	position: relative;
	color: yellow;
  }
  
  .hover-underline-animation::after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: yellow;
	transform-origin: center;
	transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover::after {
	transform: scaleX(1);
	transform-origin: center;
  }

@media (max-width: 1200px) {
  #introimage {
    margin: 1% 5% 1% 5%;
    height: 250px;
    float: right;
    border: yellow solid 2px;
    border-radius: 15%;
  }

  #art,
  #usn,
  #hike {
    max-width: 100%;
    height: auto;
    margin: 2%;
    margin-top: 5%;
    width: 300px;
    border-radius: 15%;
  }

  #github,
  #email,
  #linkedin {
    width: 250px;
    height: 250px;
    display: flex;
    margin: auto;
  }

  .navContainer {
    font-size: 3vh;
    justify-content: center;
  }

  .linkbox {
    display: table;
    margin: auto;
  }

  .linkcolumn {
    display: table-row;
  }

  .bannertext {
    text-align: center;
    position: relative;
    top: 40%;
    left: 50%;
    font-size: 5vh;
    transform: translate(-50%, -40%);
    font-family: 'Bebas Neue', cursive;
  }

  .containerText,
  .containerTextAbout {
    margin: 0 5% 5% 5%;
    text-align: justify;
  }

  .containerTextAbout h2 {
    text-align: center;
  }

  .containerTextIntro {
    padding-left: 5%;
    padding-right: 5%;
    margin: 0;
    text-align: start;
    height: auto;
  }

  .carimg {
    margin-top: 5vh;
    margin-bottom: 10vh;
    display: block;
    border-radius: 25%;
    cursor: grab;
    max-width: 100%;
    height: auto;
  }

  .containerText2 {
    display: flex;
    table-layout: fixed;
    flex-direction: column;
    margin: 0 5%;
  }

  .skillsbox {
    background-color: inherit;
    font-size: 20px;
    height: 25vh;
    width: 50%;
    margin: 2% auto;
    text-align: center;
    padding: 5%;
  }
}

@media (max-width: 500px) {
  #introimage {
    margin-right: 5px;
    margin-left: 5px;
    height: 150px;
    float: right;
    border: yellow solid 2px;
  }

  #github,
  #email,
  #linkedin {
    width: 150px;
    height: 150px;
    display: flex;
    margin: auto;
  }

  #art,
  #usn,
  #hike {
    padding: 0;
    max-width: 100%;
    height: auto;
    width: 400px;
    margin: auto;
    border-radius: 15%;
  }

  .navContainer {
    font-size: 5vh;
    justify-content: center;
  }

  .linkbox {
    display: table;
    margin: auto;
    margin-bottom: -15%;
  }

  .linkcolumn {
    display: table-row;
  }

  .bannertext {
    text-align: center;
    position: relative;
    top: 30%;
    left: 50%;
    font-size: 5vh;
    transform: translate(-50%, -30%);
    font-family: 'Bebas Neue', cursive;
  }

  .bannertext p {
    font-size: 4vh;
    color: white;
  }

  .containerText,
  .containerTextAbout {
    margin: 0 5% 5% 5%;
    text-align: justify;
  }

  .containerTextAbout h2 {
    text-align: center;
  }

  .containerTextIntro {
    padding-left: 5%;
    padding-right: 5%;
    margin: 0;
    text-align: start;
    height: auto;
  }

  .languagesTitle {
    padding-top: 10%;
  }

  .carimg {
    display: block;
    border-radius: 25%;
    cursor: grab;
    max-width: 100%;
    height: auto;
    width: 275px;
  }

  .containerText2 {
    display: flex;
    table-layout: fixed;
    flex-direction: column;
    margin: 0 5%;
  }

  .skillsbox {
    background-color: inherit;
    font-size: large;
    height: 40vh;
    width: auto;
    margin: 2%;
    text-align: center;
    padding: 5%;
  }
}