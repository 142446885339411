* {
	margin: 0;
	padding: 0;
}
.navbar {
	display: flex;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: 0;
	cursor: pointer;
}

.background {
	background: rgb(255, 255, 255);
	background-blend-mode: darken;
	background-size: cover;
}

.footer {
	background-color: #000;
}

.nav-list {
	width: 70%;
	display: flex;
	align-items: center;
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo img {
	width: 180px;
	border-radius: 50px;
}

.nav-list li {
	list-style: none;
	padding: 26px 30px;
}

.nav-list li a {
	text-decoration: none;
	color: #000;
}

.rightnav {
	width: 30%;
	text-align: right;
}

#search {
	padding: 5px;
	font-size: 17px;
	border: 2px solid rgb(0, 0, 0);
	border-radius: 9px;
}

.box-main {
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	max-width: 80%;
	margin: auto;
	height: 80%;
}

.firsthalf {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.secondhalf {
	width: 30%;
}

.secondhalf img {
	width: 70%;
	border: 4px solid white;
	border-radius: 150px;
	display: block;
	margin: auto;
}

.text-big {
	font-weight: 500;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 30px;
}

.text-small {
	font-size: 18px;
}

.btn {
	margin-left: 20px;
	height: 33px;
	width: 70px;
	color: #fff;
	background-color: #000;
	cursor: pointer;
}

.btn-sm {
	padding: 6px 10px;
	vertical-align: middle;
}

.section {
	height: 200px;
	display: flex;
	align-items: center;
	background-color: rgb(250, 250, 250);
	justify-content: space-between;
}

.section-Left {
	flex-direction: row-reverse;
}

.center {
	text-align: center;
}

.text-footer {
	text-align: center;
	padding: 30px 0;
	font-family: 'Ubuntu', sans-serif;
	display: flex;
	justify-content: center;
	color: #fff;
}